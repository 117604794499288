body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clock {
  font-family: Arial, sans-serif;
  font-size: 24px;
  color: #333;
}

.logo {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: block;
}

.typography-flex {
  display: flex;
  justify-content: space-between;
}

.title-left {
  text-align: left;
}

.value-right {
  text-align: right;
}

.scrollableContent {
  overflow-y: scroll; /* This will add a vertical scrollbar to the element */
  height: 800px; /* Adjust the height as needed */
}